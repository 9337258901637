import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";

const SingleTextField = ({ focus, autoFocus, ...restProps }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus || (focus && autoFocus)) {
      inputRef.current.select();
    }
  }, [autoFocus, focus]);

  return (
    <TextField
      inputRef={inputRef}
      size="small"
      placeholder="-"
      sx={{
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      inputProps={{
        sx: {
          textAlign: "center",
          fontWeight: "fontWeightBold",
        },
      }}
      focused={focus}
      autoFocus={focus && autoFocus}
      {...restProps}
    />
  );
};

SingleTextField.propTypes = {
  focus: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default SingleTextField;
