import dayjs from "dayjs"

const initialState = {
  callHistoryList: [],
  loading: false,
  loadError: false,
  page: 0,
  rowsPerPage: 10,
  searchKey: "",
  totalCount: 0,
  endDate: dayjs().format(),
  startDate: dayjs().subtract(15, "days").format(),
}

export default initialState
