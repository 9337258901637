import React, { useState } from "react";
import {
  TextField,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const CopyBlock = ({
  initialCode,
  heading,
  description,
  linkUrl,
  linkText,
}) => {
  const [code, setCode] = useState(initialCode || "");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  return (
    <Paper
      Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingInline: 3,
        paddingBlock: 1,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Grid container item xs={12} sx={{ paddingInline: 2, paddingBlock: 4 }}>
        {heading ? (
          <Grid item xs={12} marginBlock={1}>
            <Typography variant="subtitle1" align="start">
              {heading}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          container
          mb={2}
          item
          xs={12}
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Typography variant="body1" align="start" fontWeight="bold">
            {description}
          </Typography>
          <a
            href={linkUrl}
            style={{ fontWeight: "bold", textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        </Grid>
        <Grid container item xs={12} style={{ position: "relative" }}>
          <Grid container item xs={12}>
            <TextField
              sx={{ paddingInline: 2 }}
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>
          <Box style={{ position: "absolute", top: -5, right: -30 }}>
            <Tooltip
              title="Copied!"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={() => setTooltipOpen(false)}
            >
              <IconButton onClick={handleCopy} color="primary">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CopyBlock;
