import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchDataAndProceedWithToolkit } from "../helpers"
import { DEVELOPERS_API_DELETE_TOKEN, DEVELOPERS_API_GENERATE_TOKEN, DEVELOPERS_API_SHOW_TOKEN } from "./api"
import { METHOD_TYPES } from "../../configs/constants"
import initialState from "./inititalState";

const developersSlice = createSlice({
    name: "developers",
    initialState,
    reducers: {
        updateIntegrationState(state, action) {
            return { ...state, ...action.payload }
        },
    },
})



export const developersApiGenerateToken = createAsyncThunk(
    "developers/generateApiKey",
    async (data = {}, helpers) =>
        fetchDataAndProceedWithToolkit(
            {
                url: DEVELOPERS_API_GENERATE_TOKEN,
                method: METHOD_TYPES.GET,
                loader: !data?.showLoader,
            },
            helpers
        )
)

export const developersApiShowToken = createAsyncThunk(
    "developers/showApiKey",
    async (data = {}, helpers) =>
        fetchDataAndProceedWithToolkit(
            {
                url: DEVELOPERS_API_SHOW_TOKEN,
                method: METHOD_TYPES.GET,
                modalLoader: true,

            },
            helpers
        )
)
export const developersApiDeleteToken = createAsyncThunk(
    "developers/deleteApiKey",
    async (data = {}, helpers) =>
        fetchDataAndProceedWithToolkit(
            {
                url: DEVELOPERS_API_DELETE_TOKEN,
                method: METHOD_TYPES.DELETE,
                loader: !data?.showLoader,
            },
            helpers
        )
)

export const { updateDevelopersState } = developersSlice.actions
export default developersSlice.reducer