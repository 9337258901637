export const VERIFY_HUBSPOT = "/integration/hubspot-oauth-callback"
export const INSTALL_HUBSPOT = "/integration/installHubSpot"
export const INTEGRATE_CAL = "/integration/meetSchedulers/cal/integrate"
export const INTEGRATE_ELEVENLABS_ADD_EDIT = "/integration/addElevenLabsKey"
export const GET_ELEVENLAB_VOICES = "/integration/getElevenLabVoices"
export const GENERATE_ELEVENLAB_VOICES = "integration/generateElevenLabVoice"
export const DELETE_ELEVENLABS_INTEGRATE_DETAILS = "/integration/deleteElevenLabsKey"
export const GET_PUT_UPDATE_INTEGRATE_CAL_DETAILS = "/integration/meetSchedulers/cal/integration"
export const GET__INTEGRATE_ELEVENLABS_DETAILS = "/integration/showElevenLabsKey"
export const FETCH_ALL_CAL_EVENTS_DETAILS = "/integration/meetSchedulers/cal/fetchEvents"
export const SYNC_CONTACTLIST_FROM_HUBSPOT = "/contact-list/syncContactFromHubspot"
export const VERIFY_GOHIGHLEVEL = "/integration/ghl-oauth-callback"
export const INSTALL_GOHIGHLEVEL = "/integration/installGoHighLevel"
export const SYNC_CONTACTLIST_FROM_GOHIGHLEVEL = "/contact-list/syncContactFromGoHighLevel"
