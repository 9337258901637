import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "./index.css";
import "react-quill/dist/quill.snow.css";
import {
  filterEnvVariablesBySearchString,
  findIdAndChannelByNameInEnvVars,
} from "../../../src/data/configs/utils";
import {
  MenuItem,
  Popover,
  Paper,
  ClickAwayListener,
  MenuList,
} from "@mui/material";

// Register the custom format
const EmbedBlots = ReactQuill.Quill.import("blots/embed");

export class TsMentionBlot extends EmbedBlots {
  static blotName = "ts-mention";
  static tagName = "span";

  static create(data) {
    const node = super.create();
    if (data && data.name) {
      const name = data.name;
      node.innerHTML = name;

      //listen for deletion of child and if child delete delete node also
      //ctrl or option + backspace in quill deletes inside html of custom blot
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation && mutation.type === "childList") {
            // Child nodes have changed

            if (node.children && node.children.length === 0) {
              // No child nodes, delete the parent
              node.remove();
              observer.disconnect();
            }
          }
        });
      });

      // Configuration for the observer (we are interested in changes to child nodes)
      const config = { childList: true };

      // Start observing the target node for configured mutations
      observer.observe(node, config);

      node.id = "ts-mention-blot";
      node.classList.add("ts-mention");

      // store data
      node.setAttribute("data-name", data.name);
      node.setAttribute("data-id", data.id);
      return node;
    }
    return node;
  }

  static value(domNode) {
    const { name, id } = domNode.dataset;
    return { name, id };
  }
}

ReactQuill.Quill.register(TsMentionBlot, true);

const formats = ["ts-mention"];

const VariableInputEditor = ({
  formattedEnvVariables,
  placeholder,
  value,
  onTextChange,
  wordLimit = 1500,
  style,
  innerStyle = {},
  className,
  disableSpaces,
  disablePaste,
  handleChange,
  autoFocus,
  editorRef,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [variableListShown, setVariableListShown] = useState(false);
  const [variableSearchStr, setVariableSearchStr] = useState("");
  const [filteredEnvVariables, setFilteredEnvVariables] = useState([]);
  const userCaretPosition = useRef({});
  const [htmlText, setHtmlText] = useState(value);
  const [selectedTextLength, setSelectedTextLength] = useState(0);

  const editorModules = useRef({
    keyboard: {
      bindings: {
        custom: [
          {
            key: "enter",
            shiftKey: false,
            handler: () => {},
          },
        ],
      },
    },
    toolbar: false,
  });

  // Keys to exclude
  const excludedKeysForSearch = [
    "Shift",
    "Control",
    "Alt",
    "Meta",
    "Tab",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Enter",
    "Escape",
    "Home",
    "End",
    "PageUp",
    "PageDown",
    "Insert",
    "Delete",
    "Backspace",
    "CapsLock",
    "ContextMenu",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "F10",
    "F11",
    "F12",
    // Add more keys as needed
  ];

  const handleKeyCommand = (e) => {
    if (e.keyCode === 13) {
      // document.execCommand("insertHTML", false, "")
      e.preventDefault();
    }
    //  else if (
    //     disablePaste &&
    //     e.key.toLowerCase() === "v" &&
    //     (e.ctrlKey || e.metaKey)
    // ) {
    //     //disable paste in input editor
    //     e.preventDefault()
    // }
    else if (e.key === "@") {
      //if @ is pressed show the dropdown then clear the old search string
      setVariableListShown(true);
      setVariableSearchStr("@");
    } else if (e.keyCode === 8 && variableSearchStr !== "") {
      let clipFrom = -1;
      if (selectedTextLength !== 0) clipFrom *= selectedTextLength;
      // if backspace is pressed then slice the search string from end
      setVariableSearchStr(variableSearchStr.slice(0, clipFrom));
      setSelectedTextLength(0);
    } else if (e.keyCode === 8) {
      setSelectedTextLength(0);
    } else if (e.keyCode === 32) {
      //if space is pressed and list is shown, cleanup
      if (variableListShown && variableSearchStr) {
        e.preventDefault();
        addTagOnSpace();
        dropdownCleanup();
      } else if (!variableListShown && variableSearchStr) {
        if (variableSearchStr.at(-1) !== " ")
          setVariableSearchStr(String(variableSearchStr + " "));
        else setVariableSearchStr("");
      }
    } else if (e.key === "Tab" || e.keyCode === 9) {
      //if tab is pressed when list is shown then do not insert tab
      if (variableListShown) {
        setVariableListShown(false);
      }
    } else if (
      e.key.length === 1 &&
      !(e.ctrlKey || e.metaKey) &&
      !excludedKeysForSearch.includes(e.key)
    ) {
      //if no functionality is defined on key and variable list is shown
      //it has to be a search string
      if (variableSearchStr !== "" || variableListShown) {
        setVariableSearchStr(String(variableSearchStr + e.key));
      }
    }
  };

  const handleSelectionChange = () => {
    const caretPos = window.getSelection();
    if (caretPos) {
      setSelectedTextLength(caretPos.length);
    } else {
      setSelectedTextLength(0);
    }
  };

  const dropdownCleanup = () => {
    setVariableListShown(false);
    setVariableSearchStr("");
  };

  const addTagOnSpace = () => {
    //extract starting and ending index of @
    const caretPos = editorRef.current.editor.getSelection();
    const matchedIds = findIdAndChannelByNameInEnvVars(
      formattedEnvVariables,
      variableSearchStr.slice(1).trim()
    );
    if (matchedIds && matchedIds.length > 0) {
      //allot first user in the list
      const curr = matchedIds[0];
      if (curr) {
        const envVarName = curr;
        const i = caretPos.index - envVarName.length - 1;
        replaceTextByMention(
          {
            start: i,
            end: envVarName.length + 1,
          },
          {
            start: i,
            value: {
              name: envVarName,
              id: "123",
            },
          },
          {
            index: i + 2,
            length: 0,
          }
        );
      }
    }
  };

  const replaceTextByMention = (
    deleteParams,
    insertParams,
    selectionParams
  ) => {
    setVariableListShown(false);
    const quill = editorRef.current.editor;
    quill.focus();
    quill.deleteText(deleteParams.start, deleteParams.end);
    quill.insertEmbed(
      insertParams.start,
      "ts-mention",
      insertParams.value,
      "user"
    );
    quill.insertEmbed(insertParams.start + 1, "span", "hello", "user");
    quill.setSelection(selectionParams.index, selectionParams.length);
  };

  const handleDropdownItemClick = (params) => {
    const i = userCaretPosition.current.index - variableSearchStr.length;
    replaceTextByMention(
      {
        start: i,
        end: variableSearchStr.length,
      },
      {
        start: i,
        value: {
          name: params,
          id: "1234",
        },
      },
      {
        index: i + 2,
        length: 0,
      }
    );
    dropdownCleanup();
    setVariableListShown(false);
  };

  const changeDropdownVisibility = (show) => {
    setVariableListShown(show);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setVariableListShown(false);
    } else if (event.key === "Escape") {
      setVariableListShown(false);
    }
  }

  const handleWordLimit = (txt) => {
    const words = txt?.trim().split(/\s+/);
    return words?.length <= wordLimit
      ? txt
      : words.slice(0, wordLimit).join(" ");
  };

  const onChange = (txt, _, source) => {
    // Apply word limit
    const limitedText = handleWordLimit(txt);
    setHtmlText(limitedText);

    // Call onTextChange with the limited text
    if (source && source !== "api") {
      onTextChange(limitedText);
      if (editorRef.current) {
        userCaretPosition.current = editorRef.current.editor.getSelection();
      }
    }
  };

  // const onChange = (txt, _, source) => {
  //     setHtmlText(txt)
  //     if (source && source !== "api") {
  //         onTextChange(txt)
  //         if (editorRef.current) {
  //             userCaretPosition.current = editorRef.current.editor.getSelection()
  //         }
  //     }
  // }

  useEffect(() => {
    if (formattedEnvVariables) {
      const { fEnv, matchedCount } = filterEnvVariablesBySearchString(
        formattedEnvVariables,
        variableSearchStr.slice(1)
      );
      setFilteredEnvVariables([...fEnv]);
      if (matchedCount === 0) {
        changeDropdownVisibility(false);
      } else if (variableSearchStr !== "") {
        changeDropdownVisibility(true);
      }
      if (variableSearchStr === "") changeDropdownVisibility(false);
    }
  }, [formattedEnvVariables, variableSearchStr]);

  useEffect(() => {
    let editor = null;
    if (editorRef.current) {
      editor = editorRef.current.editor;
      if (autoFocus) {
        editor.focus();
        handleMouseUp();
      }
      editor.on("selection-change", handleSelectionChange);
      // editor.on('text-change', function () {
      //     var text = editor.getText();
      //     handleChange({ scriptText: text });
      // });
    }
    return () => {
      if (editor) editor.off("selection-change", handleSelectionChange);
    };
  }, [disableSpaces, autoFocus]);

  useEffect(() => {
    setHtmlText(value);
  }, [value]);

  const handleMouseUp = () => {
    const selection = window.getSelection();
    const getBoundingClientRect = () => {
      return selection.getRangeAt(0).getBoundingClientRect();
    };
    setAnchorEl({ getBoundingClientRect, nodeType: 1 });
  };

  useEffect(() => {
    const editor = editorRef.current.editor;
    editor.root.addEventListener("mouseup", handleMouseUp);
    return () => {
      editor.root.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const id = variableListShown ? "virtual-element-popover" : undefined;

  return (
    <div
      className={`ori-relative ori-animated ori-fade-in ori-hoverable-wrapper oriEditorWrapper ${className}`}
      style={{
        ...style,
        border: "1px solid lightgrey",
        borderRadius: 5,
        // marginTop: 10,
        // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <ReactQuill
        aria-describedby={id}
        id="variable-editor"
        ref={editorRef}
        theme="snow"
        style={{
          marginBlock: 10,
          marginInline: 10,
          paddingBlock: 5,
          paddingInline: 10,
          ...innerStyle,
          position: "relative",
        }}
        placeholder={placeholder}
        modules={editorModules.current}
        formats={formats}
        bounds=".oriEditorWrapper"
        onBlur={() => {
          dropdownCleanup();
        }}
        onKeyDown={handleKeyCommand}
        value={htmlText}
        onChange={onChange}
      />
      <Popover
        id={id}
        open={variableListShown}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setVariableListShown(false)}
        disableAutoFocus
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setVariableListShown(false)}>
            <MenuList
              disableAutoFocus
              autoFocusItem={false}
              id="composition-menu"
              aria-labelledby="composition-button"
              onKeyDown={handleListKeyDown}
            >
              {filteredEnvVariables.map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => handleDropdownItemClick(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </div>
  );
};

VariableInputEditor.propTypes = {
  formattedEnvVariables: PropTypes.array,
  placeholder: PropTypes.string,
  toolbarId: PropTypes.string,
  value: PropTypes.string,
  onTextChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  disableSpaces: PropTypes.bool,
  disablePaste: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

VariableInputEditor.defaultProps = {
  formattedEnvVariables: [],
  placeholder: "",
  toolbarId: "",
  value: "",
  style: {},
  className: "",
  disableSpaces: false,
  disablePaste: false,
  autoFocus: false,
};

export default VariableInputEditor;
