import clevertap from "clevertap-web-sdk";
import { log } from "./utils";

export const initiateCleverTap = () => {
  try {
    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(process.env.REACT_APP_CLEVERTAP_TOKEN);
    clevertap.spa = true;
  } catch (error) {
    log("clevertapInitiation Error", error);
  }
};

export const onUserLogin = (data) => {
  if (
    process.env.REACT_APP_CLEVERTAP_ENABLE &&
    process.env.REACT_APP_CLEVERTAP_TOKEN
  )
    clevertap.onUserLogin.push({
      Site: data,
    });
};

export const setLocation = (data) => {
  if (
    process.env.REACT_APP_CLEVERTAP_ENABLE &&
    process.env.REACT_APP_CLEVERTAP_TOKEN
  )
    clevertap.getLocation(parseFloat(data[0]), parseFloat(data[1]));
};

export const cleverTap = {
  track: (eventName, data) => {
    if (
      process.env.REACT_APP_CLEVERTAP_ENABLE &&
      process.env.REACT_APP_CLEVERTAP_TOKEN
    ) {
      clevertap.event.push(eventName, data);
    }
  },
};
