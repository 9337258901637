import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import VariableInputEditor from "../../../MentionEditor";
import Box from "@mui/material/Box";
import CustomHintPopover from "../../../CustomHintPopover";
import { Alert, Button, IconButton, Tooltip } from "@mui/material";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import { handleCleverTapBtnClick } from "../../../../data/configs/utils";

const CallOpeningExpandingCard = ({
  script = "",
  comCorrection,
  setComCorrection,
  handleChange,
  handleExpandClick,
  mentionList,
  assistant,
  fromOnboarding,
  handleChangeAssistantChangedData,
}) => {
  const editorRef = useRef(null);
  const [onceCliked, setOnceCliked] = useState(false);
  const [isOpenGenerateAssistantScript, setIsOpenGenerateAssistantScript] =
    useState(false);

  const handleClickOpenScriptDocumentation = () => {
    window.open(process.env.REACT_APP_SCRIPT_DOCUMENTATION_URL, "_blank");
  };

  const [createManually, setCreateManually] = useState(() => {
    const storedValue = localStorage.getItem("createManually");
    // If value is present in local storage, parse it to boolean
    return JSON.parse(storedValue);
  });

  const handleOpenGenerateAssistantScript = () => {
    setIsOpenGenerateAssistantScript(true);
    handleCleverTapBtnClick("Generate Assistant Script");
  };

  const assistantCallOpeningMessage = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.callOpeningMessage
  );

  const [callOpeningMessage, setCallOpeningMessage] = useState(
    assistantCallOpeningMessage ?? ""
  );

  useEffect(() => {
    setCallOpeningMessage(assistantCallOpeningMessage ?? "");
  }, [assistantCallOpeningMessage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            display="flex"
            alignItems="center"
            variant="subtitle2"
            color="text.secondary"
          >
            Welcome Message
            <CustomHintPopover
              size="small"
              maxWidth={400}
              // hintTitle={'Bot Temperature Setting'}
              transformHorizontalPosition={"left"}
              transformVerticalPosition={"center"}
              anchorHorizontalPosition={"right"}
              anchorVerticalPosition={"top"}
              hintContent={
                <p>
                  This is the first message that your AI assistant will say once
                  the call is picked up. <br />
                  <br /> <strong>Additional Note:</strong>Make use of variables
                  to personalise it for each contact. ( type @ in the text box
                  to use it )
                </p>
              }
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              // gap: 2,
              // padding: 1,
              // paddingBlock: 2,
            }}
            onClick={handleExpandClick}
          >
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Box sx={{ width: "100%", marginBottom: 0 }}>
                  <VariableInputEditor
                    style={{
                      minHeight: "100%",
                      minWidth: "100%",
                      paddingTop: "5px",
                    }}
                    innerStyle={{
                      margin: 0,
                      paddingInline: "16px",
                    }}
                    formattedEnvVariables={mentionList}
                    placeholder="eg: Hi, How are you doing? Do you have a minute to talk?"
                    value={callOpeningMessage ?? ""}
                    onTextChange={(htmlText) => {
                      handleChangeAssistantChangedData({
                        callOpeningMessage: htmlText,
                      });
                      setCallOpeningMessage(htmlText);
                    }}
                    editorRef={editorRef}
                    wordLimit={200}
                  />
                  {comCorrection?.comWarnActive ? (
                    <Typography
                      paddingInline={2}
                      variant="subtitle2"
                      color="error"
                    >
                      {comCorrection.msg}
                    </Typography>
                  ) : null}
                  {(callOpeningMessage?.trim() === "<p><br></p>" ||
                    !callOpeningMessage?.trim()) && (
                    <Typography
                      paddingInline={2}
                      variant="subtitle2"
                      color="error"
                    >
                      Assistant's Call Opening Message is required !
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallOpeningExpandingCard;
