import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import initialState from "./initialState"
import { fetchDataAndProceedWithToolkit } from "../helpers"
import {
  CREATE_KNOWLEDGE_BASE,
  UPDATE_KNOWLEDGE_BASE,
  DELETE_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE_LIST,
  DOWNLOAD_KNOWLEDGE_BASE_DATA,
} from "./api"
import { METHOD_TYPES } from "../../configs/constants"

const knowledgeBasesSlice = createSlice({
  name: "knowledgeBases",
  initialState,
  reducers: {
    updateKnowledgeBasesState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledgeBaseList.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getKnowledgeBaseList.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBases) {
          state.knowledgeBaseList = payload.data.knowledgeBases
          state.loading = false
          state.loadError = false
        }
      })
      .addCase(getKnowledgeBaseList.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(createKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBase)
          state.knowledgeBaseList = [
            ...state.knowledgeBaseList,
            payload.data.knowledgeBase,
          ]
      })
      .addCase(updateKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedKnowledgeBase?._id) {
          const index = state.knowledgeBaseList.findIndex(
            (item) => item._id === payload.data.updatedKnowledgeBase._id
          )
          if (index > -1) {
            state.knowledgeBaseList = [
              ...state.knowledgeBaseList.slice(0, index),
              payload.data.updatedKnowledgeBase,
              ...state.knowledgeBaseList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(deleteKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?._id) {
          const index = state.knowledgeBaseList.findIndex(
            (item) => item._id === payload.data._id
          )
          if (index > -1) {
            state.knowledgeBaseList = [
              ...state.knowledgeBaseList.slice(0, index),
              ...state.knowledgeBaseList.slice(index + 1),
            ]
          }
        }
      })
  },
})

export const createKnowledgeBase = createAsyncThunk(
  "knowledgeBases/createKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const updateKnowledgeBase = createAsyncThunk(
  "knowledgeBases/updateKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.PUT,
        contentType: "multipart/form-data",
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const getKnowledgeBaseList = createAsyncThunk(
  "knowledgeBases/getKnowledgeBaseList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_KNOWLEDGE_BASE_LIST,
        data,
        loader: false,
      },
      helpers
    )
)

export const deleteKnowledgeBase = createAsyncThunk(
  "knowledgeBases/deleteKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const downloadKnowledgeData = createAsyncThunk(
  "knowledgeBases/downloadKnowledgeData",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DOWNLOAD_KNOWLEDGE_BASE_DATA,
        data,
      },
      helpers
    )
)

export const { updateKnowledgeBasesState } = knowledgeBasesSlice.actions
export default knowledgeBasesSlice.reducer
