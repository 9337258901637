import { createSlice } from "@reduxjs/toolkit"

import initialState from "./initialState"

const voiceAgentSlice = createSlice({
  name: "voiceAgent",
  initialState,
  reducers: {
    updateVoiceAgentState(state, action) {
      return { ...state, ...action.payload }
    },
  },
})

export const { updateVoiceAgentState } = voiceAgentSlice.actions
export default voiceAgentSlice.reducer
