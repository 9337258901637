const initialState = {
  contactList: [],
  loading: false,
  loadError: true,

  //Edit contacts Page
  contactsLoading: false,
  selectedContactList: null,
  contacts: [],
  contactsPage: 0,
  contactsRowsPerPage: 10,
  contactsSearchText: "",
  contactsTotalCount: 0
}

export default initialState
