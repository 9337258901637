import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import {
  GET_ALL_ASSISTANTS,
  CREATE_ASSISTANT,
  UPDATE_ASSISTANT,
  DELETE_ASSISTANT,
  GET_ASSISTANT,
  ATTACH_KNOWLEDGE_BASE,
  TRAIN_ASSISTANT,
  GET_SAMPLE_AUDIO,
  GET_ALL_ACTIONS,
  POST_CREATE_ACTION,
  DELETE_ACTION,
  GET_LINKEDIN_DATA,
  POST_GATHER_DETAILS,
  POST_CREATE_SCRIPT_WITH_AI,
  GET_MENTION_LIST,
  EDIT_ACTION,
  GET_ASSISTANT_ACTIONS,
  GET_ASSISTANT_VOICES,
  GET_ASSISTANT_LANGUAGE_GENDER,
} from "./api";
import { METHOD_TYPES } from "../../configs/constants";

const assistantsSlice = createSlice({
  name: "assistants",
  initialState,
  reducers: {
    updateAssistantsState(state, action) {
      return { ...state, ...action.payload };
    },
    updateAssistantsChangedData(state, action) {
      return {
        ...state,
        assistantUpdatedInfo: {
          ...state.assistantUpdatedInfo,
          ...(action.payload.key === "assistantUpdatedInfo"
            ? action.payload.data
            : action.payload.enable
            ? action.payload.data
            : null),
        },
        assistantInitialInfo: {
          ...state.assistantInitialInfo,
          ...(action.payload.key === "assistantInitialInfo"
            ? action.payload.data
            : action.payload.enable
            ? action.payload.data
            : null),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAssistants.pending, (state) => {
        state.loading = true;
        state.loadError = false;
      })
      .addCase(getAllAssistants.fulfilled, (state, { payload }) => {
        if (payload?.data?.assistants)
          state.assistants = payload.data.assistants;
        state.loading = false;
        state.loadError = false;
      })
      .addCase(getAllAssistants.rejected, (state) => {
        state.loading = false;
        state.loadError = true;
      })
      .addCase(createAssistant.fulfilled, (state, { payload }) => {
        if (payload?.data?.assistant?.assistantId) {
          state.assistants.unshift(payload.data.assistant);
        }
      })
      .addCase(updateAssistant.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedAssistant?.assistantId) {
          const index = state.assistants.findIndex(
            (assistant) =>
              assistant.assistantId ===
              payload.data.updatedAssistant.assistantId
          );
          if (index > -1)
            state.assistants[index] = payload.data.updatedAssistant;
        }
      })
      .addCase(deleteAssistant.fulfilled, (state, { payload }) => {
        if (payload?.data?.assistantId) {
          state.assistants = state.assistants.filter(
            (item) => item.assistantId !== payload.data.assistantId
          );
        }
      })
      .addCase(attachKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedAssistant?.assistantId) {
          const index = state.assistants.findIndex(
            (assistant) =>
              assistant.assistantId ===
              payload.data.updatedAssistant.assistantId
          );
          if (index > -1)
            state.assistants[index] = payload.data.updatedAssistant;
        }
      })
      .addCase(getMentionList.fulfilled, (state, { payload }) => {
        state.workspaceContactListCol = payload.data.allCols;
      });
  },
});

export const getAllAssistants = createAsyncThunk(
  "assistants/getAllAssistants",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_ASSISTANTS,
        data,
        loader: false,
      },
      helpers
    )
);

export const createAssistant = createAsyncThunk(
  "assistants/createAssistant",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_ASSISTANT,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
);

export const updateAssistant = createAsyncThunk(
  "assistants/updateAssistant",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_ASSISTANT,
        data,
        method: METHOD_TYPES.PUT,
        loader: false,
      },
      helpers
    )
);

export const deleteAssistant = createAsyncThunk(
  "assistants/deleteAssistant",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_ASSISTANT,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
);

export const getAssistant = createAsyncThunk(
  "assistants/getAssistant",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ASSISTANT,
        data,
        loader: !data.hideLoader,
        axiosArgs: { signal: helpers.signal },
      },
      helpers
    )
);
export const getAssistantVoices = createAsyncThunk(
  "assistants/getAssistantVoices",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ASSISTANT_VOICES,
        data,
        loader: false,
      },
      helpers
    )
);
export const getLanguageAndGender = createAsyncThunk(
  "assistants/getLanguageAndGender",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ASSISTANT_LANGUAGE_GENDER,
        data,
        loader: !data.hideLoader,
        axiosArgs: { signal: helpers.signal },
      },
      helpers
    )
);

export const getAssistantActions = createAsyncThunk(
  "assistants/getAssistantActions",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ASSISTANT_ACTIONS,
        method: METHOD_TYPES.GET,
        data,
        loader: false,
      },
      helpers
    )
);

export const attachKnowledgeBase = createAsyncThunk(
  "assistants/attachKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ATTACH_KNOWLEDGE_BASE,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const trainAssistant = createAsyncThunk(
  "assistants/trainAssistant",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: TRAIN_ASSISTANT,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const getSampleAudio = createAsyncThunk(
  "assistants/getSampleAudio",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_SAMPLE_AUDIO,
        method: METHOD_TYPES.POST,
        data,
        // modalLoader: true,
      },
      helpers
    )
);
export const getAllActions = createAsyncThunk(
  "/action/getAllActions",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_ACTIONS,
        method: METHOD_TYPES.GET,
        data,
        loader: false,
      },
      helpers
    )
);
export const postCreateAction = createAsyncThunk(
  "/action/create",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: POST_CREATE_ACTION,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);
export const deleteAction = createAsyncThunk(
  "/action/delete",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_ACTION,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
);
export const updateAction = createAsyncThunk(
  "/action/edit",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: EDIT_ACTION,
        method: METHOD_TYPES.PUT,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const getLinkedInData = createAsyncThunk(
  "/getLinkedInDetails",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_LINKEDIN_DATA,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
);
export const postGatherDetails = createAsyncThunk(
  "/assistant/gatherDetailsAI",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: POST_GATHER_DETAILS,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const postCreateScriptWithAI = createAsyncThunk(
  "/assistant/createScriptWithAI",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: POST_CREATE_SCRIPT_WITH_AI,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const getMentionList = createAsyncThunk(
  "/contactList/getWorkspaceContactListCols",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_MENTION_LIST,
        method: METHOD_TYPES.GET,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const { updateAssistantsState, updateAssistantsChangedData } =
  assistantsSlice.actions;
export default assistantsSlice.reducer;
