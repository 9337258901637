import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const TableLoader = () => {
  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Skeleton
          variant="rounded"
          width={160}
          height={40}
          sx={{ bgcolor: "primary.light" }}
        />
        <Skeleton variant="rounded" width={80} height={40} />
      </Box>
      <Box display="flex" gap={{ xs: 2, md: 4 }} mt={2}>
        {[1, 2, 3, 4].map((ele) => (
          <Skeleton key={ele} variant="rounded" height={50} sx={{ flex: 1 }} />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        {[1, 2, 3, 4, 5, 6.7, 8, 9, 10].map((item) => {
          return (
            <Box key={item} display="flex" gap={{ xs: 2, md: 4 }}>
              {[1, 2, 3, 4].map((ele) => (
                <Skeleton
                  key={ele}
                  variant="rounded"
                  height={36}
                  sx={{ flex: 1 }}
                />
              ))}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default TableLoader;
