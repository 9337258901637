import PropTypes from "prop-types";
import { useState } from "react";
import Box from "@mui/material/Box";

import SingleTextField from "./SingleOtpInput";

const OtpTextField = ({
  size,
  isNumberType,
  autoFocus,
  disabled,
  onChange,
  textFieldProps,
  ...restProps
}) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(size).fill(""));

  const handleOtpChange = (otp) => {
    const otpValue = otp.join("");
    onChange(otpValue);
  };

  const getRightValue = (str) => {
    let changedValue = str;

    if (!isNumberType || !changedValue) {
      return changedValue;
    }

    return Number(changedValue) >= 0 ? changedValue : "";
  };

  const focusInput = (inputIndex) => {
    const selectedIndex = Math.max(Math.min(size - 1, inputIndex), 0);
    setActiveInput(selectedIndex);
  };

  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  const changeCodeAtFocus = (str) => {
    const updatedOTPValues = [...otpValues];
    updatedOTPValues[activeInput] = str[0] || "";
    setOTPValues(updatedOTPValues);
    handleOtpChange(updatedOTPValues);
  };

  const handleOnChange = (e) => {
    const val = getRightValue(e.currentTarget.value);
    if (!val) {
      e.preventDefault();
      return;
    }
    changeCodeAtFocus(val);
    focusNextInput();
  };

  const handleOnFocus = (index) => () => {
    focusInput(index);
  };

  const handleOnKeyDown = (e) => {
    const pressedKey = e.key;
    switch (pressedKey) {
      case "Backspace":
      case "Delete": {
        e.preventDefault();
        if (otpValues[activeInput]) {
          changeCodeAtFocus("");
          focusPrevInput();
        } else {
          focusPrevInput();
        }
        break;
      }
      case "ArrowLeft": {
        e.preventDefault();
        focusPrevInput();
        break;
      }
      case "ArrowRight": {
        e.preventDefault();
        focusNextInput();
        break;
      }
      default: {
        if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
          e.preventDefault();
        }

        break;
      }
    }
  };

  const handleOnBlur = () => {
    setActiveInput(-1);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text/plain")
      .trim()
      .slice(0, size - activeInput)
      .split("");
    if (pastedData) {
      let nextFocusIndex = 0;
      const updatedOTPValues = [...otpValues];
      updatedOTPValues.forEach((val, index) => {
        if (index >= activeInput) {
          const changedValue = getRightValue(pastedData.shift() || val);
          if (changedValue) {
            updatedOTPValues[index] = changedValue;
            nextFocusIndex = index;
          }
        }
      });
      setOTPValues(updatedOTPValues);
      setActiveInput(Math.min(nextFocusIndex + 1, size - 1));
      handleOtpChange(updatedOTPValues);
    }
  };

  return (
    <Box display="flex" gap={{ xs: 1, sm: 2 }} {...restProps}>
      {Array(size)
        .fill("")
        .map((_, index) => (
          <SingleTextField
            key={index}
            type={isNumberType ? "number" : "text"}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onKeyDown={handleOnKeyDown}
            onBlur={handleOnBlur}
            onPaste={handleOnPaste}
            onChange={handleOnChange}
            disabled={disabled}
            {...textFieldProps}
          />
        ))}
    </Box>
  );
};

OtpTextField.propTypes = {
  size: PropTypes.number.isRequired,
  isNumberType: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
};

OtpTextField.defaultProps = {
  size: 6,
  isNumberType: true,
  autoFocus: false,
  disabled: false,
  textFieldProps: {},
};

export default OtpTextField;
