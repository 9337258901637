import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import Box from "@mui/material/Box";
import * as _ from "lodash";
import Typography from "@mui/material/Typography";
import { log, parseQueryString } from "../../data/configs/utils";
import { verifyHubspot } from "../../data/store/integrationSlice";
import { APP_PAGES, ROUTE_PATH } from "../../data/configs/constants";
import { getUserProfile } from "../../data/store/authSlice";
import { updatePageState } from "../../data/store/pageSlice";
import verifyImage from "../../data/assets/img/verify.jpg";
import tick from "../../data/assets/img/tick.svg";
import Timer from "../../data/configs/redirectTimer";
import ConfettiComponent from "../ConfettiAnimation";

const HubSpotVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const restParams = useLocation();
  const restParamsObj = parseQueryString(restParams?.search);

  const handleTimerFinish = () => {
    navigate(ROUTE_PATH.INTEGRATIONS + "?verification=Successful");
  };

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.INTEGRATION_VERIFICATION,
        currentActiveModule: APP_PAGES.INTEGRATION_VERIFICATION,
      })
    );
  }, [dispatch]);

  const hubspotVerificationRedirect = () => {
    dispatch(verifyHubspot({ ...restParamsObj }))
      .unwrap()
      .then((res) => {
        if (!showDialog) setShowDialog(true);
        enqueueSnackbar("Verification sent Successfully!", {
          variant: "success",
        });

        dispatch(getUserProfile())
          .unwrap()
          .then(() => {
            enqueueSnackbar("Integration Successful", { variant: "success" });
          })
          .catch((err) => log("ERROR-getUserProfile: ", err));
      })
      .catch((err) => log("ERROR-hubspotVerification: ", err));
  };

  useEffect(() => {
    if (!_.isEmpty(restParamsObj)) {
      hubspotVerificationRedirect({ ...restParamsObj });
    }
  }, [restParams]);

  return (
    <Box
      sx={{
        width: "100%", // 100% of viewport width
        height: "100%", // 100% of viewport height
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "center", // optional, adjust as needed
        alignItems: "center", // optional, adjust as needed
      }}
    >
      <ConfettiComponent endTime={2} />
      <div style={{ position: "relative", maxWidth: "500px", height: "auto" }}>
        <Box
          px={2}
          display="flex"
          gap={4}
          alignItems="center"
          justifyContent="center"
          style={{
            position: "absolute",
            top: 8,
            zIndex: 2,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle2">Redirecting in...</Typography>
          <Timer seconds={6} onFinish={handleTimerFinish} />
        </Box>
        <img
          src={verifyImage}
          alt="Verification"
          style={{
            borderRadius: "24px",
            width: "100%",
            height: "100%",
            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
          }}
        />
        <Box
          display="flex"
          justifyContent="center"
          px={2}
          alignItems="center"
          gap={2}
          style={{
            position: "absolute",
            bottom: 8,
            zIndex: 2,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          <img
            src={tick}
            alt="tick"
            style={{ width: "30px", height: "30px" }}
          />
          <Typography variant="h4">HubSpot Integration Successful</Typography>
        </Box>
      </div>
    </Box>
  );
};

export default HubSpotVerification;
