import { enqueueSnackbar } from "notistack";
import { BREAKPOINT_VALUES, CLEVERTAP_EVENT, LOCAL_STORAGE } from "./constants";
import { cleverTap } from "./clevertap";

export const log = (...arg) => {
  if (process.env.NODE_ENV !== "production") console.log(...arg);
};
export const handleCleverTapBtnClick = (btnName) => {
  const user = getDataFromLocalStorage(LOCAL_STORAGE.APP_USER, null);
  cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
    workspaceId: user?.workspace ?? "",
    buttonName: btnName,
  });
};

export const handleCleverTapBtnClickWithPathname = (btnName, pathname, route) => {
  const user = getDataFromLocalStorage(LOCAL_STORAGE.APP_USER, null);
  cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
    workspaceId: user?.workspace ?? "",
    buttonName: btnName?.trim() ?? "button name undefined",
    onboarding: (pathname && route && typeof pathname === "string" && typeof route === "string") ? pathname === route : false
  });
};

export const downloadFile = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = ""; // The download attribute triggers the download
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const clearAllDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.APP_USER);
  localStorage.removeItem(LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT);
};

export const clearDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const checkAppSumoUser = (planName) => {
  const appSumoPlanName = [
    "AppSumo Deal License Tier 1",
    "AppSumo Deal License Tier 2",
    "AppSumo Deal License Tier 3",
  ];
  return appSumoPlanName.includes(planName);
};

export const getDataFromLocalStorage = (key, defaultValue) => {
  try {
    let data = localStorage.getItem(key);
    return data && data !== undefined && data !== "undefined"
      ? JSON.parse(data)
      : defaultValue;
  } catch (err) {
    console.log(err);
    localStorage.removeItem(key);
    window.location.reload(true);
  }
};

export const handleCopyClick = (value) => {
  if (value?.length > 0) {
    navigator?.clipboard
      ?.writeText(value)
      ?.then(() => {
        enqueueSnackbar("Copied to clipboard!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      });
  }
};

export const removeEmptyValues = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      const nested = removeEmptyValues(obj[key]);
      if (Object.keys(nested).length > 0) {
        acc[key] = nested;
      }
    } else if (obj[key] !== undefined && obj[key] !== "") {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const setDataInLocalStorage = (key, data) => {
  const jsonData = JSON.stringify(data);
  localStorage.setItem(key, jsonData);
};

export function isValidUrl(url, httpsNotNecessary = false) {
  if (httpsNotNecessary && !/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  const regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,})([\/\w\.-]*)*\/?(\?.*)?(#.*)?$/i;
  return regex.test(url);
}

export const isValidWebhookUrl = (url) => {
  const webhookUrlRegex = /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,})(\/[\w\/.-]*)?(\?.*)?$/i;
  return webhookUrlRegex.test(url);
}

export function containsSpecialCharacters(input) {
  // Regular expression to match any character that is not a letter, number, or space
  const regex = /[^a-zA-Z0-9\s]/;
  // Test the input against the regex
  return regex.test(input);
}

export function containsNumericalValues(input) {
  // Regular expression to match any numerical value
  const regex = /\d/;
  // Test the input against the regex
  return regex.test(input);
}

export const getDeviceData = () => {
  const deviceData = {
    extraSmall: window.innerWidth < BREAKPOINT_VALUES.sm,
    small:
      window.innerWidth < BREAKPOINT_VALUES.md &&
      window.innerWidth >= BREAKPOINT_VALUES.sm,
    medium: window.innerWidth >= BREAKPOINT_VALUES.md,
    large: window.innerWidth >= BREAKPOINT_VALUES.lg,
    extraLarge: window.innerWidth >= BREAKPOINT_VALUES.xl,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    orientation: window.matchMedia("(orientation:landscape)").matches
      ? "landscape"
      : "portrait",
  };
  return deviceData;
};

export const checkPassword = (password) => {
  /*
   * password must be 8 to 15 characters length
   * at least one lowercase letter
   * at least one uppercase letter
   * at least one numeric digit
   * at least one special character
   */
  const pattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  return pattern.test(password);
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar("Copied Successfully");
    } catch (err) {
      log("Failed to copy: ", err);
    }
  }
};

export const debounced = (func, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
      timerId = null;
    }, delay);
  };
};

export const openUrlInNewTab = (url, target = "_blank") => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, target);
    win.focus();
  }
};

export function isValidWebsiteUrl(url) {
  // Check if the protocol is present and valid
  if(url?.length < 5){
    return [false, 'Error: Not valid Url']
  }
  const protocolRegex = /^(https?:\/\/)/;
  if (!protocolRegex.test(url)) {
    return [false, 'Error: Missing or invalid protocol (http or https is required).'];
  }

  // Check if the domain and extension are valid
  const domainRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;
  if (!domainRegex.test(url)) {
    return [false, 'Error: Invalid domain or domain extension (must be a valid domain with at least a 2-character extension).'];
  }

  // Ensure no consecutive slashes after the domain
  const pathRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\/\s]*)?(\/[^\s]*)?$/;
  const consecutiveSlashCheck = /\/\/+/; // Checks for consecutive slashes

  // Extract the part of the URL after the domain (path, query, fragment)
  const domainEnd = url.indexOf('/', url.indexOf('//') + 2);
  const afterDomain = domainEnd !== -1 ? url.substring(domainEnd) : '';

  if (consecutiveSlashCheck.test(afterDomain)) {
    return [false, 'Error: Consecutive slashes are not allowed in the path.'];
  }

  // Optionally check for query parameters and fragments
  const fullRegex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\/\s]*)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/;
  if (!fullRegex.test(url)) {
    return [false, 'Error: Invalid query parameters or fragments.'];
  }

  // If all checks pass, the URL is valid
  return [true, 'Valid URL'];
}


export const convertStringToHTML = (htmlString) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, "text/html");
  return html.body;
};

export const parseQueryString = function (query) {
  const obj = {};
  if (query.length) {
    if (query[0] === "?" || query[0] === "#") {
      query = query.substring(1, query.length);
    }
    const tempArr = query.split("&");
    tempArr.forEach(function (str) {
      const arr = str.split("=");
      if (arr.length === 2) {
        obj[arr[0]] = arr[1];
      }
    });
  }
  return obj;
};

// function replaceInnerHtmlOfTsMention(htmlText, fn) {
//   const originalHtml = convertStringToHTML(htmlText)

//   function replaceElements(element) {
//     const children = element.childNodes
//     for (let i = children.length - 1; i >= 0; i--) {
//       const node = children[i]
//       if (
//         node.nodeType === 1 &&
//         node.tagName.toLowerCase() === "span" &&
//         node.id === "ts-mention-blot"
//       ) {
//         fn(node)
//       } else {
//         replaceElements(node)
//       }
//     }
//   }

// replaceElements(originalHtml)
// return originalHtml.innerHTML
// }

// function checkIfEnvVariableExistById(envVariables, id) {
//   const { name, channel } = findNameAndChannelByIdInEnvVars(envVariables, id)
//   if (name === "") return { status: false, name }
//   return { status: true, name, channel }
// }

// export function insertNameInTsMention(envVariables, htmlText, classList) {
//   return replaceInnerHtmlOfTsMention(htmlText, node => {
//     //check if the node exists in env vars by id
//     const id = node.getAttribute("data-id")
//     const { status: ifEnvExists, name: realName } = checkIfEnvVariableExistById(
//       envVariables,
//       id
//     )
//     const [currChannel, tempName] = node.getAttribute("data-name").split("@")
//     if (!ifEnvExists) {
//       //node does not exist
//       //replace node with simple temp text
//       node.parentNode.replaceChild(
//         document.createTextNode("@" + tempName),
//         node
//       )
//     } else {
//       node.innerHTML = "@" + realName
//       //update data-name
//       if (tempName !== realName)
//         node.setAttribute("data-name", currChannel.concat("@").concat(realName))
//     }
//   })
// }

// export function replaceTsMentionById(envVariables, htmlText) {
//   return replaceInnerHtmlOfTsMention(htmlText, node => {
//     const id = node.getAttribute("data-id")
//     const [_, tempName] = node.getAttribute("data-name").split("@")
//     const { status: ifEnvExists } = checkIfEnvVariableExistById(
//       envVariables,
//       id
//     )
//     if (!ifEnvExists) {
//       //if does not exist replace it with just the name
//       node.parentNode.replaceChild(document.createTextNode(tempName), node)
//     } else {
//       //if exists replace it with id
//       node.parentNode.replaceChild(
//         document.createTextNode("{{" + id + "}}"),
//         node
//       )
//     }
//   })
// }

// export function replaceIdByTsMentionName(envVariables, htmlText) {
//   if (htmlText) {
//     const replacedText = htmlText.replace(/{{(.*?)}}/g, function (match, id) {
//       const {
//         status: ifEnvExists,
//         name: realName,
//         channel,
//       } = checkIfEnvVariableExistById(envVariables, id)

//       if (ifEnvExists) {
//         return channel + "@" + realName
//       }
//       return id
//     })
//     return replacedText
//   }
//   return htmlText
// }

// export function replaceIdByTsMentionBlot(
//   envVariables,
//   htmlText,
//   regexp = /{{(.*?)}}/g
// ) {
//   if (htmlText) {
//     const replacedText = htmlText.replace(regexp, function (match, id) {
//       const {
//         status: ifEnvExists,
//         name: realName,
//         channel,
//       } = checkIfEnvVariableExistById(envVariables, id)

//       if (ifEnvExists) {
//         return `<span id="ts-mention-blot" class="ts-mention-${channel}" data-name="${channel}@${realName}" data-id="${id}">@${realName}</span>`
//       }
//       return id
//     })
//     return replacedText
//   }
//   return htmlText
// }

// export function insertIdInTsMention(envVariables, htmlText) {
//   return replaceInnerHtmlOfTsMention(htmlText, node => {
//     const id = node.getAttribute("data-id")
//     const { status: ifEnvExists, name: realName } = checkIfEnvVariableExistById(
//       envVariables,
//       id
//     )
//     const [currChannel, tempName] = node.getAttribute("data-name").split("@")
//     if (!ifEnvExists) {
//       //node does not exist
//       //replace node with simple temp text
//       node.parentNode.replaceChild(
//         document.createTextNode("@" + tempName),
//         node
//       )
//     } else {
//       // Replace the custom element's inner html with the id
//       node.innerHTML = `{{${id}}}`
//       node.setAttribute("data-name", currChannel.concat("@").concat(realName))
//     }
//   })
// }

export function filterEnvVariablesBySearchString(
  envVariables,
  variableSearchStr
) {
  let matchedCount = 0;
  const fEnv = [];
  envVariables.forEach((variable) => {
    if (
      variableSearchStr &&
      variable.toLowerCase().includes(variableSearchStr.toLowerCase())
    ) {
      matchedCount++;
      fEnv.push(variable);
    } else if (!variableSearchStr) {
      matchedCount++;
      fEnv.push(variable);
    }
  });

  return { matchedCount, fEnv };
}

export const findIdAndChannelByNameInEnvVars = (envVariables, name) => {
  const idResults = [];
  envVariables.forEach((variables) => {
    if (variables.toLowerCase().includes(name.toLowerCase()))
      idResults.push(variables);
  });
  return idResults;
};

export const toQueryString = (params) => {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
};

// export const findNameAndChannelByIdInEnvVars = (envVariables, id) => {
//   let name = ""
//   let channel = ""
//   envVariables.forEach(group => {
//     const children = group.children
//     children.forEach(child => {
//       if (child.key === id) {
//         name = child.label
//         channel = group.key
//       }
//     })
//   })
//   return { name, channel }
// }
