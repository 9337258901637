// hooks/useCountDownTimer.ts

import { useState, useRef, useEffect } from "react";

const padStart = (num) => {
  return num.toString().padStart(2, "0");
};

const formatMs = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;
  seconds = seconds % 60;

  let str = `${padStart(minutes)}:${padStart(seconds)}`;

  if (hours > 0) {
    str = `${padStart(hours)}:${str}`;
  }

  return str;
};

export const useCountDownTimer = (seconds) => {
  const [time, setTime] = useState(seconds);

  const interval = useRef();

  useEffect(() => {
    if (time > 0) {
      interval.current = setInterval(() => {
        setTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = undefined;
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [time]);

  return {
    time: time === 0 ? false : formatMs(time),
    resetTime: () => {
      setTime(seconds);
    },
  };
};
