import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Timer = ({ seconds, onFinish, visible = true }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 0) {
          clearInterval(timer);
          onFinish(); // Callback function when the timer finishes
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the timer on unmount
  }, [seconds, onFinish]);

  // Format the time to display
  const formatTime = (time) => {
    if (time < 10) return `0${time}`;
    return time;
  };
  return (
    <>
      {visible ? (
        <Typography variant="subtitle2" fontWeight="bold">
          {formatTime(timeLeft)} seconds
        </Typography>
      ) : null}
    </>
  );
};

export default Timer;
