import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Typography } from "@mui/material";

const ScriptWithAIComponent = ({
  setsetAIScriptClosed,
  backendChat,
  businessName,
  businessDescription,
  setisEnableSaveToAssistant,
  assistant,
  handleChange,
  handleScriptChange,
  streamedText,
  setStreamedText,
}) => {
  const textareaRef = useRef(null);
  const token = useSelector((state) => state.authDetails.token);
  const requestBody = {
    businessName: businessName,
    details: businessDescription,
    goal: assistant?.goal,
    pastMessages: backendChat,
    botName: assistant?.name,
  };

  const fetchDataAndStream = async () => {
    try {
      let baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(baseUrl + "assistant/createScriptWithAI", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const reader = response.body.getReader();

      const readStream = async () => {
        setisEnableSaveToAssistant(false);
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setisEnableSaveToAssistant(true);
            break;
          }
          const text = new TextDecoder("utf-8").decode(value);
          setStreamedText((prevText) => prevText + text);
        }
      };
      handleChange({ script: streamedText });
      readStream().catch((error) => {
        console.error("Error reading stream:", error);
      });
    } catch (error) {
      console.error("Error in fetching data:", error);
    }
  };

  useEffect(() => {
    setsetAIScriptClosed(false);
    fetchDataAndStream();
    setStreamedText("");
  }, []);

  useEffect(() => {
    // Function to scroll the textarea to the bottom
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [streamedText]);

  return (
    <Grid container justifyContent="center" sx={{ height: "100%" }} spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          style={{
            padding: "16px",
            maxWidth: "100%",
            overflowY: "auto",
            maxHeight: "70vh",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
          }}
        >
          <textarea
            ref={textareaRef}
            value={streamedText}
            onChange={(e) => handleScriptChange(e.target.value)}
            style={{
              width: "100%",
              border: "none",
              resize: "none",
              fontSize: "1rem",
              height: "100%",
              fontFamily: "inherit",
              lineHeight: "1.5",
              backgroundColor: "transparent",
              "&:focus": { outline: "none" },
            }}
            rows={18} // Adjust rows as needed
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ScriptWithAIComponent;
