import { createSlice } from "@reduxjs/toolkit"

import initialState from "./initialState"

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettingsState(state, action) {
      return { ...state, ...action.payload }
    },
  },
})

export const { updateSettingsState } = settingsSlice.actions
export default settingsSlice.reducer
