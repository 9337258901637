import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

import IMG_LOGO_AVATAR_LIGHT from "../../data/assets/img/logoAvatarLight.png";
import IMG_LOGO_AVATAR_DARK from "../../data/assets/img/logoAvatarDark.png";
import { verifyEmail, getUserProfile } from "../../data/store/authSlice";
import { updatePageState } from "../../data/store/pageSlice";
import {
  ROUTE_PATH,
  APP_PAGES,
  THEME_MODE,
} from "../../data/configs/constants";

import ShowMessage from "../ShowMessage";
import SpinnerLoader from "../SpinnerLoader";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.VERIFY_EMAIL,
        currentSiderModule: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("token")) {
      dispatch(verifyEmail({ token: searchParams.get("token") }))
        .unwrap()
        .then((res) => {
          dispatch(getUserProfile())
            .unwrap()
            .then((res) => {
              setIsVerified(true);
              setTimeout(() => navigate(ROUTE_PATH.ASSISTANTS), 3000);
            });
        })
        .catch((err) => {
          navigate(ROUTE_PATH.ASSISTANTS);
        });
    } else {
      enqueueSnackbar("Verification token is missing");
      navigate(ROUTE_PATH.ASSISTANTS);
    }
  }, [dispatch, navigate]);

  if (isVerified)
    return (
      <ShowMessage
        title="Your Email has been verified"
        message="You will soon be redirected to Home page. Click Below to go now"
        containerProps={{
          py: 8,
        }}
        src={
          themeMode === THEME_MODE.DARK
            ? IMG_LOGO_AVATAR_DARK
            : IMG_LOGO_AVATAR_LIGHT
        }
        sx={{
          height: 200,
          width: 200,
          my: 4,
        }}
      >
        <Button
          startIcon={<HomeIcon />}
          variant="contained"
          color="secondary"
          sx={{ my: 2 }}
        >
          Dashboard
        </Button>
      </ShowMessage>
    );
  else return <SpinnerLoader brand />;
};

export default VerifyEmail;
