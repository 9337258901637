import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { fetchDataAndProceedWithToolkit } from "../helpers"
import initialState from "./initialState"
import {
  GET_ALL_PHONE_NUMBERS,
  CREATE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER,
  DELETE_PHONE_NUMBER,
} from "./api"
import { METHOD_TYPES } from "../../configs/constants"

const phoneNumbersSlice = createSlice({
  name: "phoneNumbers",
  initialState,
  reducers: {
    updatePhoneNumbersState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPhoneNumbers.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getAllPhoneNumbers.fulfilled, (state, { payload }) => {
        if (payload.data.phoneNumbers) {
          state.loading = false
          state.loadError = false
          state.phoneNumbers = payload.data.phoneNumbers
        }
      })
      .addCase(getAllPhoneNumbers.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(createPhoneNumber.fulfilled, (state, { payload }) => {
        if (payload?.data?.phoneNumber) {
          state.phoneNumbers.unshift(payload.data.phoneNumber)
        }
      })
      .addCase(updatePhoneNumber.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedPhoneNumber?._id) {
          const index = state.phoneNumbers.findIndex(
            (item) => item._id === payload.data.updatedPhoneNumber._id
          )
          if (index > -1)
            state.phoneNumbers[index] = payload.data.updatedPhoneNumber
        }
      })
      .addCase(deletePhoneNumber.fulfilled, (state, { payload }) => {
        if (payload?.data?._id) {
          state.phoneNumbers = state.phoneNumbers.filter(
            (item) => item._id !== payload.data._id
          )
        }
      })
  },
})

export const getAllPhoneNumbers = createAsyncThunk(
  "phoneNumbers/getAllPhoneNumbers",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_PHONE_NUMBERS,
        data,
        loader: false,
      },
      helpers
    )
)

export const createPhoneNumber = createAsyncThunk(
  "phoneNumbers/createPhoneNumber",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_PHONE_NUMBER,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
)

export const updatePhoneNumber = createAsyncThunk(
  "phoneNumbers/updatePhoneNumber",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_PHONE_NUMBER,
        method: METHOD_TYPES.PUT,
        data,
        modalLoader: true,
      },
      helpers
    )
)

export const deletePhoneNumber = createAsyncThunk(
  "phoneNumbers/deletePhoneNumber",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_PHONE_NUMBER,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const { updatePhoneNumbersState } = phoneNumbersSlice.actions
export default phoneNumbersSlice.reducer
