import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";

const AssistantEditableName = ({ handleSaveChanges }) => {
  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(assistantInitialData?.name ?? "The Bot");

  useEffect(() => {
    setText(assistantInitialData?.name ?? "The Bot");
  }, [assistantInitialData]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);

    handleSaveChanges(
      true,
      {
        name: text,
      },
      true
    );
    // You can handle the save functionality here (e.g., make an API call or update state elsewhere)
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {isEditing ? (
        <TextField
          variant="outlined"
          value={text}
          onChange={(e) => setText(e.target.value)}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: "10px", // Border radius for the dropdown menu
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px", // Applying 10px border-radius inline for the input
            },
          }}
        />
      ) : (
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {text}
        </Typography>
      )}
      <IconButton
        onClick={isEditing ? handleSaveClick : handleEditClick}
        sx={{ ml: 1 }}
      >
        {isEditing ? (
          <SaveIcon fontSize="medium" />
        ) : (
          <EditIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
};

export default AssistantEditableName;
