import { enqueueSnackbar } from "notistack";

import { resetReduxState } from ".";

const middleware = (store) => (next) => {
  const initialReduxState = store.getState();
  return (action) => {
    if (action?.error) {
      if (action?.payload?.status === 401) {
        store.dispatch(resetReduxState());
      } else if (action?.payload?.data?.message) {
        if (
          !(action?.payload?.data?.data?.hideErrorMessage ||
          action?.payload?.forceFEHideEnquebar)
        )
          enqueueSnackbar(action?.payload?.data?.message, { variant: "error" });
      }
    }

    if (resetReduxState.match(action)) {
      const currentState = store.getState();
      const actionWithInitialState = {
        ...action,
        payload: {
          ...initialReduxState,
          pageDetails: {
            ...initialReduxState.pageDetails,
            themeMode: currentState.pageDetails.themeMode,
          },
          authDetails: {
            ...initialReduxState.authDetails,
            user: null,
            token: "",
          },
        },
      };
      return next(actionWithInitialState);
    }

    next(action);
  };
};

export default middleware;
