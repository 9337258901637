import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import AssistantCOMandScript from "./AssistantCOMandScript";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import {
  APP_PAGES,
  CLEVERTAP_EVENT,
  ROUTE_PATH,
} from "../../data/configs/constants";
import { cleverTap } from "../../data/configs/clevertap";
import {
  getAllActions,
  getAssistant,
  getAssistantActions,
  getMentionList,
  updateAssistant,
  updateAssistantsChangedData,
  updateAssistantsState,
} from "../../data/store/assistantsSlice";
import { log } from "../../data/configs/utils";
import { updatePageState } from "../../data/store/pageSlice";
import AssistantSetup from "../AssistantSetup";
import AssistantRightComponent from "../AssistantRightComponent";
import ErrorBoundary from "../ErrorBoundary";
import TestAssistantForm from "../TestAssistantForm";
import { enqueueSnackbar } from "notistack";

const AssistantConfig = () => {
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  const assistantUpdatedInfo = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo
  );
  const assistantInitialInfo = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  //This state will handle the original assistant data from the API Response
  // const [assistantData, setAssistantData] = useState({});
  //This state will handle the changes in the assistant data from the ui
  // const [assistantActions, setAssistantActions] = useState({});
  //This state will handle the changes in the assistant data from the ui
  // const [assistantChangeData, setAssistantChangeData] = useState({});
  // state for handling Ids and sending in the edit apis
  // const [assistantTransferToAgentIds, setAssistantTransferToAgentIds] =
  // useState([]);
  // const [assistantWebhookIds, setAssistantWebhookIds] = useState([]);
  // const [
  //   assistantAppointmentSchedulingIds,
  //   setAssistantAppointmentSchedulingIds,
  // ] = useState([]);
  // const [assistantActionsAllIdsArray, setAssistantActionsAllIdsArray] =
  //   useState([]);

  const { pathname, state } = useLocation();
  const [selectedTab, setSelectedTab] = useState("train");
  const [preBot, setPreBot] = useState(false);
  const handleCloseDrawer = () => setShowDrawer(false);

  const [initialBookAMeetingTemplate, setInititalBookAMeetingTemplate] =
    useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [phoneNumbers1, setPhoneNumbers1] = useState([]);
  const [elevenlabsGeneratedVoices, setElevenlabsGeneratedVoices] = useState(
    []
  );
  const [inititalWebhookUrls, setInititalWebhookUrls] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaveClicked, setIsSavedClicked] = useState(false);
  const [applySampleScriptForOnboarding, setApplySampleScriptForOnboarding] =
    useState(false);
  const [assistant, setAssistant] = useState({});
  const [actionIds, setActionIds] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fromOnboarding, setFromOnboarding] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  const [callAllActionsApi, setCallAllActionsApi] = useState({
    transferToAgent: false,
    webhook: false,
    smsBooking: false,
  });

  const handleAllActionApiCall = (key, value) => {
    setCallAllActionsApi((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [comForceAllowDialog, setComForceAllowDialog] = useState(false);
  const [comForceAllowPayload, setComForceAllowPayload] = useState({});
  const [comCorrection, setComCorrection] = useState({
    msg: "",
    comWarnActive: false,
  });


  const handleButtonClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleTalkToAgentClick = () => {
    cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
      workspaceId,
      buttonName: "Test Your Assistant",
      onboarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
    });
    setDialogData({
      showTestAssistant: true,
      testAssistant: {},
    });
  };
  const handleCOMForceDialogToggle = () => {
    setComForceAllowDialog(!comForceAllowDialog);
  };

  const handleCOMForceSave = () => {
    setComForceAllowDialog(false);
    handleSaveChanges(false, comForceAllowPayload);
  };

  function isAnyActionTrue(callAllActionsApi) {
    return Object.values(callAllActionsApi).some((value) => value === true);
  }

  // dispatch(
  //   updateAssistantsState({
  //     assistantInitialInfo: res.data.assistant,
  //   })
  // );

  const handleFetchAllActionData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("id")) {
      dispatch(
        getAssistantActions({
          assistantId: queryParams.get("id"),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            setCallAllActionsApi({
              transferToAgent: false,
              webhook: false,
              smsBooking: false,
            });
            dispatch(
              updateAssistantsChangedData({
                key: "assistantInitialInfo",
                data: res.data,
                // enable : true,
              })
            );
          }
        })
        .catch((err) => {
          log("ERR-getAllActionsV2", err);
        });
    }
  };

  useEffect(() => {
    if (isAnyActionTrue(callAllActionsApi)) {
      handleFetchAllActionData();
    }
  }, [callAllActionsApi]);

  // useEffect(() => {
  //   console.log("assistantChangeData", assistantChangeData);
  // }, [assistantChangeData]);
  // Define the saveChanges function with debouncing

  const debouncedSaveChanges = useCallback(
    _.debounce(() => {
      handleSaveChanges();
    }, 3000), // Delay time in milliseconds
    [assistantUpdatedInfo, dispatch]
  );

  useEffect(() => {
    if (!_.isEmpty(assistantUpdatedInfo)) {
      debouncedSaveChanges();
    }
    // Cleanup on unmount or when `assistantUpdatedInfo` changes
    return () => {
      debouncedSaveChanges.cancel(); // Cancel previous debounce if assistantUpdatedInfo changes
    };
  }, [assistantUpdatedInfo, debouncedSaveChanges]);

  const handleSaveChanges = (
    forceSave = true,
    params = {},
    callActionsApi = true
  ) => {
    const queryParams = new URLSearchParams(window.location.search);
    const additionalParams = forceSave ? {} : { comForceSave: true };
    const payloadData = assistantUpdatedInfo;
    dispatch(
      updateAssistantsState({
        assistantUpdatedInfo: {},
      })
    );

    dispatch(
      updateAssistant({
        assistantId: assistantInitialInfo?.assistantId ?? queryParams.get("id"),
        ...payloadData,
        ...params,
        ...additionalParams,
      })
    )
      .unwrap()
      .then((res) => {
        enqueueSnackbar("Changes Updated Successfully!", {
          variant: "success",
        });
        if (res?.data?.updatedAssistant) {
          dispatch(
            updateAssistantsChangedData({
              key: "assistantInitialInfo",
              data: res?.data?.updatedAssistant,
              // enable : true,
            })
          );
          // dispatch(
          //   updateAssistantsState({
          //     assistantUpdatedInfo: {},
          //   })
          // );
        }

        setIsSavedClicked(true);
        setIsChanged(false);
        if (showDrawer) handleCloseDrawer();
        if (!forceSave || comCorrection.msg)
          setComCorrection({
            msg: "",
            comWarnActive: false,
          });
        if (preBot) {
          handleTalkToAgentClick();
        }
      })
      .then(() => {
        if (callActionsApi) {
          handleFetchAllActionData();
        }
      })
      .catch((err) => {
        dispatch(
          updateAssistantsChangedData({
            key: "assistantUpdatedInfo",
            data: { ...assistantUpdatedInfo, ...payloadData },
          })
        );
        if (
          err?.data?.data?.com?.status === false &&
          err?.data?.data?.com?.message
        ) {
          setComForceAllowPayload(params);
          setComForceAllowDialog(true);
          setComCorrection({
            msg: err.data.data.com.message,
            comWarnActive: true,
          });
        }
      });
  };

  const handleCloseDialoge = (e, reason) => {
    if (reason !== "backdropClick") setDialogData({});
  };
  const handleChange = (changedValue) => {
    setAssistant((prev) => ({
      ...prev,
      ...changedValue,
    }));
    if (!isChanged) setIsChanged(true);
  };
  // const handleChangeAssistantChangedData = (changedValue) => {
  //   setAssistantChangeData((prev) => ({
  //     ...prev,
  //     ...changedValue,
  //   }));
  // };

  const getMentionListDetails = () => {
    dispatch(getMentionList())
      .unwrap()
      .then((res) => {
        setMentionList(res.data.allCols);
      })
      .catch((err) => {
        log("ERR-getMentionList", err);
      });
  };

  //This is the useEffect Responsible for updating initail assistantData this.state
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const handleError = () => {
      if (pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
        navigate(ROUTE_PATH.CREATE_FIRST_ASSISTANT);
      else navigate(ROUTE_PATH.ASSISTANTS);
    };

    if (queryParams.has("id")) {
      dispatch(
        getAssistant({
          assistantId: queryParams.get("id"),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.data?.assistant?.assistantId) {
            dispatch(
              updateAssistantsState({
                assistantInitialInfo: res.data.assistant,
              })
            );
            dispatch(
              updateAssistantsState({
                assistantUpdatedInfo: {},
              })
            );
          } else throw new Error();
        })
        .then((res) => {
          dispatch(
            getAssistantActions({
              assistantId: queryParams.get("id"),
            })
          )
            .unwrap()
            .then((res) => {
              dispatch(
                updateAssistantsChangedData({
                  key: "assistantInitialInfo",
                  data: res.data,
                  // enable : true,
                })
              );
            })
            .catch(handleError);
        })
        .catch(handleError);
    } else {
      handleError();
    }
  }, [dispatch, navigate, pathname]);

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.EDIT_ASSISTANT,
        currentActiveModule: APP_PAGES.ASSISTANTS.PAGE,
      })
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    getMentionListDetails();
  }, []);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid
          container
          display="flex"
          justifyContent="center"
          flexDirection="column"
          item
          xs={pathname === "/onboarding/updateAssistant" ? 12 : 8}
          paddingRight={1}
        >
          <Grid item xs={12} width="100%">
            <AssistantCOMandScript
              setApplySampleScriptForOnboarding={
                setApplySampleScriptForOnboarding
              }
              applySampleScriptForOnboarding={applySampleScriptForOnboarding}
              comCorrection={comCorrection}
              setComCorrection={setComCorrection}
              mentionList={mentionList}
              handleChange={handleChange}
              assistant={assistantInitialInfo}
              isSaveClicked={isSaveClicked}
              setIsSavedClicked={setIsSavedClicked}
              fromOnboarding={fromOnboarding}
              preBot={preBot}
              handleSaveChanges={handleSaveChanges}
              handleTalkToAgentClick={handleTalkToAgentClick}
            />
          </Grid>
        </Grid>
        {pathname === "/onboarding/updateAssistant" ? null : (
          <Grid container item xs={4} spacing={2}>
            <Grid item xs={12}>
              <AssistantRightComponent
                handleTalkToAgentClick={handleTalkToAgentClick}
                handleFetchAllActionData={handleFetchAllActionData}
                setCallAllActionsApi={setCallAllActionsApi}
                handleSaveChanges={handleSaveChanges}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog
        PaperProps={{
          sx: {
            overflowY: "visible",
          },
        }}
        aria-labelledby="vg-test-assistant"
        open={
          dialogData.showTestAssistant || dialogData.showVoiceAccent || false
        }
        onClose={handleCloseDialoge}
        fullWidth
      >
        <DialogContent sx={{ overflowY: "visible" }}>
          {dialogData?.showTestAssistant && (
            <ErrorBoundary onErrorCallback={handleCloseDialoge}>
              <TestAssistantForm
                assistantId={assistantInitialInfo.assistantId}
                onCancel={handleCloseDialoge}
              />
            </ErrorBoundary>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          sx: {
            maxWidth: 1000,
          },
        }}
        aria-labelledby="com-lang-miss-match-force-save"
        open={comForceAllowDialog}
        onClose={handleCOMForceDialogToggle}
        fullWidth
      >
        {" "}
        <IconButton
          aria-label="close"
          onClick={handleCOMForceDialogToggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          align="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <WarningIcon color="error" /> Warning
        </DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={10} mb={1}>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                We recommend you to type your Assistant's Call Opening Message
                in the same language as set for your Assistant, for the best
                possible experience !!
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              mt={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Button
                sx={{ textTransform: "none", minWidth: "100px" }}
                variant="outlined"
                color="primary"
                onClick={handleCOMForceSave}
              >
                Force Save
              </Button>
              <Button
                sx={{ textTransform: "none", minWidth: "100px" }}
                variant="outlined"
                onClick={handleCOMForceDialogToggle}
                color="primary"
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssistantConfig;
