import { getDataFromLocalStorage, getDeviceData } from "../../configs/utils"
import {
  LOCAL_STORAGE,
  THEME_MODE,
  BREAKPOINT_VALUES,
} from "../../configs/constants"
import { SUPER_SAVER_DEAL } from "../../configs/constants"

const initialState = {
  themeMode: getDataFromLocalStorage(LOCAL_STORAGE.THEME, THEME_MODE.LIGHT),
  deviceData: getDeviceData(),
  currentPage: "",
  currentActiveModule: "",
  siderCollapse: window.innerWidth < BREAKPOINT_VALUES.md,
  pageLoading: false,
  pageLoaderText: "",
  modalLoading: false,
  authLoading: false,
  showTicker:
    new Date().getTime() > SUPER_SAVER_DEAL.START_TIME &&
    new Date().getTime() < SUPER_SAVER_DEAL.END_TIME,
  showModal: false,
}

export default initialState
