import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { SUPER_SAVER_DEAL } from "../../data/configs/constants";
import { updatePageState } from "../../data/store/pageSlice";

const Timer = () => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(
    SUPER_SAVER_DEAL.END_TIME - new Date().getTime()
  );

  useEffect(() => {
    let timerId;

    timerId = setInterval(() => {
      const remainingTime = SUPER_SAVER_DEAL.END_TIME - new Date().getTime();
      if (remainingTime > 0)
        setTimeLeft(SUPER_SAVER_DEAL.END_TIME - new Date().getTime());
      else {
        dispatch(updatePageState({ showTicker: false, showModal: false }));
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [dispatch]);

  const formatTime = useMemo(() => {
    const totalSeconds = Math.floor(timeLeft / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    return [
      {
        label: days < 2 ? "day" : "days",
        value: days < 10 ? "0" + days : days,
      },
      {
        label: hours < 2 ? "hour" : "hours",
        value: hours < 10 ? "0" + hours : hours,
      },
      {
        label: days < 2 ? "minute" : "minutes",
        value: minutes < 10 ? "0" + minutes : minutes,
      },
      {
        label: seconds < 2 ? "seconds" : "seconds",
        value: seconds < 10 ? "0" + seconds : seconds,
      },
    ];
  }, [timeLeft]);

  return (
    <Box display="flex" alignItems="center" gap={{ xs: 0.5, md: 1 }}>
      {formatTime.map((item) => {
        return (
          <Box minWidth={42}>
            <Typography
              fontSize={{ xs: "h6.fontSize", md: "h5.fontSize" }}
              fontWeight="fontWeightBold"
              textAlign="center"
              sx={{
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                lineHeight: 1,
              }}
            >
              {item.value}
            </Typography>
            <Typography textAlign="center" component="p" variant="caption">
              {item.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Timer;
