export const SIGNUP_USER = "/user-manager/signup"
export const LOGIN_USER = "/user-manager/signin"
export const LOGOUT_USER = "/user-manager/logout"
export const FORGOT_PASSWORD = "/user-manager/forgot-password"
export const RESET_PASSWORD = "/user-manager/reset-password"
export const GET_USER_PROFILE = "/user/profile"
export const UPDATE_USER_PROFILE = "/user/edit"
export const VERIFY_EMAIL = "/user-manager/verify-email"
export const SEND_VERIFICATION_TOKEN = "/user-manager/resend-verification"
export const SUBMIT_USER_ONBOARDING_SURVEY = "/user/onboarding"
export const SEND_OTP_TO_VERIFY_MOBILE_NUMBER = "/user/sendOTP"
export const VERIFY_OTP = "/user/verifyOTP"
export const PLACE_DEMO_CALL = "/user/demoCall"
export const GET_PRICING_DETAILS = "/billing/listAvailablePlans"
export const UPDATE_USER_PLAN = "/billing/updateUserPlan"
export const GET_USER_PLAN = "/billing/getUserPlan"
export const GET_USER_PLAN_EXTRA_DETAILS = "/billing/fetchUserQuota"
export const ENTERPRISE = "/enterprise"
export const SIGNUP_PASSWORD = "/user-manager/signup-password"
export const GET_DEAL_PLANS = "/billing/listDealPlans"
export const FEEDBACK_FORM = "/user/feedback"
export const APP_SUMO_ASSIST = "/user/assist"
export const CHANGE_PASSWORD = "/user-manager/change-password"
export const CANCEL_SUBSCRIPTION = "/billing/cancelSubscription"
