import { Component } from "react";
import PropTypes from "prop-types";

import { log } from "../../data/configs/utils";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    log("ERROR_BOUNDARY:- ", error, errorInfo);
    this.setState({ hasError: true }, () => {
      if (this.props.onErrorCallback) this.props.onErrorCallback();
    });
  }

  render() {
    if (this.state.hasError) return null;

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onErrorCallback: PropTypes.func,
};

export default ErrorBoundary;
