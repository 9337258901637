import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const TransferToAgentSelectDialog = ({
  isOpen,
  onClose,
  availableAgentQuota,
  availableToChooseTransferToAgentActionsArray,
  handleAddFromExistingTransferToAgent,
  handleOptionClick,
}) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: "1400px",
          width: "600px",
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography align="center" variant="h6" fontWeight="bold">
          Transfer to Agent
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ overflowY: "visible" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "550px !important",
          }}
        >
          <Grid container item xs={8}>
            <Grid item xs={12} mb={4} sx={{ minWidth: "100%" }}>
              <Button
                style={{ minWidth: "200px", paddingBlock: 12 }}
                variant="contained"
                onClick={() => {
                  handleOptionClick("Transfer to Agent");
                  onClose();
                }}
                fullWidth
                startIcon={<AddIcon />}
                disabled={availableAgentQuota < 1}
              >
                <Typography variant="body2" textTransform="none">
                  Add New Number
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ marginBottom: 2, justifyContent: "center" }}
            >
              {availableToChooseTransferToAgentActionsArray?.length !== 0 ? (
                <FormControl style={{ width: "100%" }}>
                  <Select
                    value=""
                    displayEmpty
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        paddingBlock: 1,
                      },
                      justifyContent: "center",
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select Existing Number</em>
                    </MenuItem>
                    {availableToChooseTransferToAgentActionsArray
                      ?.filter((item) => item?.type === "transfer_to_agent")
                      ?.map((item) => (
                        <MenuItem
                          key={item._id}
                          value={item._id}
                          onClick={() => {
                            onClose();
                            handleAddFromExistingTransferToAgent(item._id);
                          }}
                        >
                          {item?.data?.phoneNumbers}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TransferToAgentSelectDialog;
