import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { useCountDownTimer } from "../../../data/hooks/useCountDownTimer";

const ResendOTP = ({ waitingTime, onResend, ...restProps }) => {
  const { time, resetTime } = useCountDownTimer(waitingTime);

  return (
    <Box display="flex" mt={0.5}>
      <Typography color="text.secondary" {...restProps}>
        Didn't received OTP.
        {time && (
          <Typography
            variant="inherit"
            component="span"
            color="inherit"
            fontSize="inherit"
            mx={0.5}
          >
            Please wait for {time}
          </Typography>
        )}
      </Typography>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        sx={{ ml: 2, py: 0 }}
        fontSize="inherit"
        disabled={time}
        onClick={() => onResend(resetTime)}
      >
        RESEND NOW
      </Button>
    </Box>
  );
};

ResendOTP.propTypes = {
  waitingTime: PropTypes.number,
  onResend: PropTypes.func,
};

ResendOTP.defaultProps = {
  waitingTime: 120,
};

export default ResendOTP;
