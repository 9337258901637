const contents = {
  title: "FAQ's",
  faqs: [
    {
      question: "What is the difference between an agent and an assistant ?",
      answer:
        "An assistant is your voice bot where as an agent is human to whom you can transfer the call in between",
    },
    {
      question: "Do you offer a free trial? How does that work ?",
      answer:
        "Yes we offer Free Trial For 7 days. We provide 30 voice minutes which you can consume during that 7 day free trial. After that those minutes will be expired.",
    },
    {
      question: "Can I upgrade or downgrade/switch plans at any time ?",
      answer:
        "Yes, You can upgrade or downgrade your plans at any time. Basically at the time of renewal you can choose which plan you want to renew with.",
    },
    {
      question: "Does my free trial limit my access to features ?",
      answer:
        "No, There is no such restrictions on any features as of now. However they are quotas associated with some features.",
    },
    {
      question: "What payment methods do you accept ?",
      answer:
        "As of now, we only accept Credit Cards for subscriptions and other purchases.",
    },
    {
      question: "Are there any setup fees or hidden charges ?",
      answer:
        "No, We don't charge any setup fee. It's completely free. And There are no hidden charges. You just have pay the subscription amount.",
    },
    {
      question: "Can I pay Quarterly ?",
      answer: "No, We only accept monthly and yearly subscriptions.",
    },
    {
      question: "Can I cancel my subscription at any time ?",
      answer:
        "Yes, you can cancel your subscription at any point of time. Your existing plan will continue to work till it expires.",
    },
    {
      question:
        "If I am a small business and want custom limits and quotas can I still opt for the Enterprise plan?",
      answer:
        "Yes, Feel free to fill the form. And we will contact as soon as possible.",
    },
    {
      question:
        "Can I roll over my remaining call quota limit to the next month ?",
      answer:
        "No, All your quota expires with the end of your subscription interval. If you are monthly plan it will renew every month without any roll over.",
    },
  ],
  supportFaqs: [
    {
      question: "Can I use dialer other than Twilio ?",
      answer:
        "Yes, We support Twilio as well as Plivo. You can go through our documentation for more information on Twilio and Plivo.",
    },
    {
      question: "Can the campaigns be edited again ?",
      answer:
        "While running a campaign it can only be paused and resumed, you cannot modify contact details in between. If you need to change contacts info within a running campaign, you will have to create a new campaign.",
    },
    {
      question:
        "Is there any particular format in which the content goes into Assistant Script?",
      answer:
        'We have provided a sample format; you can go through it. As we are using Generative AI, there are no strict restrictions on format. You can create your own formats. Please make use of the <a href="https://voicegenie.gitbook.io/voicegenie-ai/product-guides/assistants/creating-an-assistant/script/how-to-write-a-script" target="_blank">Script Documentation Page</a> to better understand how scripts work.',
    },
    {
      question:
        "Can I sync the contact list with an Excel sheet, or is updating the list a manual effort? Does it support CRM integration with HubSpot?",
      answer:
        "We have provided both options: you can update the contact list manually or by uploading an Excel sheet (a sample format is provided). Additionally, we support CRM integration with HubSpot to streamline contact management.",
    },
    {
      question: "Any limit on campaigns I can run simultaneously ?",
      answer: "Currently you can run only one campaign at a time.",
    },
    {
      question: "What post impementation support is available?",
      answer:
        "We've established a dedicated support page for your convenience. You can get in touch with our support team via email for any queries you may have. Additionally, we've implemented a 24/7 chatbot feature with live agents available to assist you.",
    },
    {
      question:
        "Can I assign different languages to different campaigns/assistants?",
      answer:
        "With our latest version, we now support multiple languages like English, Hindi, French, German, Spanish, Japanese, and more. Multilingual features are now available.",
    },
  ],
  developersFaqs: [
    {
      question: "Where can I use these keys ?",
      answer:
        "These keys (token and workspaceId) can be used to access VoiceGenie functionalities via APIs.",
    },
    {
      question: "What VoiceGenie APIs are currently available ?",
      answer: "Currently, you can add contacts to recurring campaigns via API.",
    },
    {
      question: "What happens if I delete my token while it is being used ?",
      answer:
        "If the token is deleted, all subsequent API calls will fail with an authentication error.",
    },
    {
      question: "Where can I read more about the APIs ?",
      answer:
        'Please refer to our detailed documentation on <a href="https://voicegenie.gitbook.io/voicegenie-ai/developer-documentation-1.0" target="_blank">Developer APIs </a> .',
    },
    {
      question:
        "My dashboard shows “something went wrong.” Should I be concerned ?",
      answer:
        "If the intended functionalities are unaffected, you can ignore the message. Otherwise, please contact our support team.",
    },
  ],
};

export default contents;
