export const CREATE_CONTACT_LIST = "/contact-list/add"
export const EDIT_CONTACT_LIST = "contact-list/edit"
export const GET_ALL_CONTACT_LIST = "/contact-list/workspaceContactList"
export const DELETE_CONTACT_LIST = "/contact-list/deleteList"
export const ADD_CONTACTS_VIA_FILE = "/contact-list/addListData"
export const GET_CONTACTS = "/contact-list/getAllContactListData"
export const CREATE_CONTACT = "/contact-list/createContactListDataItem"
export const UPDATE_CONTACT = "/contact-list/editListData"
export const DELETE_CONTACT = "/contact-list/deleteListData"
export const CREATE_CONTACT_FORM_LIST = "/contact-list/getTransformedContactListCols"

